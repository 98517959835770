<template>
  <app-header :routes="routes" />
  <Main>
    <sd-cards>
      <a-row type="flex" justify="center">
        <a-col :lg="20" :sm="22" :xs="24">
          <contact-card-wrapper>
            <div class="contact-card">
              <div class="user-info">
                <ul>
                  <li>
                    {{ $t('models.bankAccountInformation.attributes.bankName') }} :
                    {{ bankAccountInformation?.value?.bank_name || '--' }}
                  </li>
                  <li>
                    {{ $t('models.bankAccountInformation.attributes.bic') }} :
                    {{ bankAccountInformation?.value?.bic || '--' }}
                  </li>
                  <li>
                    {{ $t('models.bankAccountInformation.attributes.iban') }} :
                    {{ bankAccountInformation?.value?.iban || '--' }}
                  </li>
                  <li>
                    {{ $t('models.bankAccountInformation.attributes.beneficiaryName') }} :
                    {{ bankAccountInformation?.value?.beneficiary_name || '--' }}
                  </li>
                  <li>
                    {{ $t('models.bankAccountInformation.attributes.currency') }} :
                    {{ bankAccountInformation?.value?.currency || '--' }}
                  </li>
                </ul>
              </div>
            </div>
          </contact-card-wrapper>
        </a-col>
      </a-row>
    </sd-cards>
  </Main>
</template>

<script>
import {defineComponent, computed} from 'vue';
import {useStore} from 'vuex';
import {Main} from '@/components/shared/styledComponents/Main';
import {ContactCardWrapper} from '@/components/shared/cards/style';

export default defineComponent({
  name: 'BankAccountInformation',
  components: {ContactCardWrapper, Main},
  setup() {
    const routes = [{
      name: 'BankAccountInformation',
      breadcrumbName: 'layout.bankAccountInformation'
    }];
    const {
      getters,
      dispatch
    } = useStore();

    dispatch('toggleLoading', true);
    dispatch('getSystemConfigurationByKey', 'bank_account_information').finally(() => dispatch('toggleLoading', false));

    const bankAccountInformation = computed(() => getters.bankAccountInformation);

    return {
      routes,
      bankAccountInformation
    };
  }
});
</script>

<style lang="scss" scoped>
.contact-card .user-info {
  margin-top: 0px !important;
  border-top: 0px !important;
  padding-top: 10px !important;
}
</style>
